import React from "react";
import styled from '@emotion/styled'
import Layout from "../components/layout";
import translate from "../utils/translation";
import { MainTitle } from "../components/common/title";
import cloud from '../../static/images/cloud.svg';

const Wrapper = styled.section`
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2f2f2f;
  
  display: flex;
  align-items: center;
  width: 100%;
  background: url(${cloud}) right center no-repeat;
  background-size: 90%;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 150px;
  @media (max-width: 760px) {
    padding: 0 24px;
  }
`;

const Content = styled.p`
  width: 100%;
  margin: 0 0 16px;

  @media (max-width: 760px) {
    width: 100%;
    letter-spacing: 0.8px;
  }
`;

export const SubSectionTitle = styled.h3`
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 1.2;

  @media (max-width: 760px) {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
`;

export default () =>
  <Layout>
    <Wrapper>
      <Container>
        <MainTitle>
          {translate("terms")}
        </MainTitle>
        <Content>
          {/* <SubSectionTitle>Sub Heading 1</SubSectionTitle> */}
          {translate("termsAndConditions.effectiveDate")}
        </Content>
        <Content>
          {translate("termsAndConditions.intro1")}
        </Content>
        <Content>
          {translate("termsAndConditions.intro2")}
        </Content>
        <Content>
          {translate("termsAndConditions.intro3")}
        </Content>
        <Content>
          {translate("termsAndConditions.intro4")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.license.title")}</SubSectionTitle>
          {translate("termsAndConditions.license.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.license.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.userData.title")}</SubSectionTitle>
          {translate("termsAndConditions.userData.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.userData.part2")}
        </Content>
        <Content>
          {translate("termsAndConditions.userData.part3")}
        </Content>
        <Content>
          {translate("termsAndConditions.userData.part4")}
        </Content>
        <Content>
          {translate("termsAndConditions.userData.part5")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.payment.title")}</SubSectionTitle>
          {translate("termsAndConditions.payment.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.payment.part2")}
        </Content>
        <Content>
          {translate("termsAndConditions.payment.part3")}
        </Content>
        
        <Content>
          <SubSectionTitle>{translate("termsAndConditions.continuousMembership.title")}</SubSectionTitle>
          {translate("termsAndConditions.continuousMembership.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.freeTrial.title")}</SubSectionTitle>
          {translate("termsAndConditions.freeTrial.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.termination.title")}</SubSectionTitle>
          {translate("termsAndConditions.termination.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.termination.part2")}
        </Content>
        <Content>
          {translate("termsAndConditions.termination.part3")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.accessToServices.title")}</SubSectionTitle>
          {translate("termsAndConditions.accessToServices.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.changesToServicesAndTerms.title")}</SubSectionTitle>
          {translate("termsAndConditions.changesToServicesAndTerms.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.changesToServicesAndTerms.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.linkToThirdParty.title")}</SubSectionTitle>
          {translate("termsAndConditions.linkToThirdParty.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.contentToEmail.title")}</SubSectionTitle>
          {translate("termsAndConditions.contentToEmail.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.dataCollection.title")}</SubSectionTitle>
          {translate("termsAndConditions.dataCollection.part1")}
        </Content>
        <Content>
          {translate("termsAndConditions.dataCollection.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.dataCollection.ownership.title")}</SubSectionTitle>
          {translate("termsAndConditions.dataCollection.ownership.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.limitationOfLiability.title")}</SubSectionTitle>
          {translate("termsAndConditions.limitationOfLiability.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("termsAndConditions.warrantyDisclaimers.title")}</SubSectionTitle>
          {translate("termsAndConditions.warrantyDisclaimers.part1")}
        </Content>

      </Container>
    </Wrapper>
  </Layout>;